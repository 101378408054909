const ProformaItem = class {
    constructor(object) {
        if (object === undefined) {
            this.uid = null;
            this.id = null
            this.code = null
            this.productId = null
            this.product = null;
            this.productObj = null;
            this.hasTax = false;
            this.amount = 0.0;
            this.discount = 0.0;
            this.readableDiscount = 0.0;
            this.unitPrice = 0.0;
            this.totalPrice = 0.0;
            this.priceList = [0.0];
        } else {
            this.uid = object.uid || this.makeid(10);
            this.id = object.id || null;
            this.code = object.code || null;
            this.productId = object.productId;
            this.product = object.product;
            this.productObj = object.productObj;
            this.hasTax = object.hasTax;
            this.amount = object.amount || 1;
            this.discount = object.discount;
            this.readableDiscount = this.discount * 100.0;
            this.unitPrice = object.unitPrice;
            this.totalPrice = this.unitPrice * this.amount * (1 - this.discount);
            this.priceList = object.priceList;
        }
        this.readableTax = this.hasTax ? "SI" : "NO";
    }

    applyDiscount(discount) {
        this.discount = discount;
        this.totalPrice = this.unitPrice * this.amount * (1 - this.discount);
    }

    makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
}

export default ProformaItem