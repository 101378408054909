<template>
	<div class="grid p-fluid">
        <Toast/>
        <div class="col-12">
			<div class="card" :style="{'background-color':this.cardBackgroundColor}">
                <div style="width: 100%; display: flex;">
                    <h2 style="font-size: large;">Proforma - {{ this.$store.getters.defaultActivity.name }}</h2>
                    <Button v-show="showNewButton" icon="pi pi-plus" class="newsale-button p-button-rounded p-button-success mr-2 mb-2" style="margin-left: auto;" @click="newProforma"/>
                </div>
				<div class="p-fluid formgrid grid">
					<div class="field col-12">
                        <div class="grid formgrid" style="margin-top: 10px;">
                            <div class="col-12">
                                <div class="card">
                                    <h5>Datos del Cliente</h5>
                                    <Button icon="pi pi-search" class="p-button-rounded p-button-info mr-2 mb-2" style="position: absolute; top: 20px; right: 20px;" @click="showCustomerDialog" v-show="this.proforma.id === null"/>
                                    <div class="grid formgrid">
                                        <div class="col-12 mb-2 lg:col-6 lg:mb-0">
                                            <label for="customerName">Razón Social</label>
                                            <InputText v-model="this.proforma.customer.name" id="customerName" type="text" :class="{'p-invalid': this.nameError}" @change="checkForm()" :disabled="this.proforma.id !== null"/>
                                            <small class="p-invalid" v-if="this.nameError">Ingrese el nombre o razón social</small>
                                        </div>
                                        <div class="col-12 mb-2 lg:col-6 lg:mb-0">
                                            <label for="customerId">Cédula/RUC/Pasaporte</label>
                                            <InputText v-model="this.proforma.customer.identification" id="customerId" type="text" :class="{'p-invalid': this.identificationError}" @change="checkForm()" :disabled="this.proforma.id !== null" />
                                            <small class="p-invalid" v-if="this.identificationError">La cédula debe tener 10 dígitos y el RUC 13 dígitos</small>
                                        </div>
                                    </div>
                                    <div class="grid formgrid">
                                        <div class="col-12 mb-2 lg:col-4 lg:mb-0">
                                            <label for="customerEmail" style="margin-top: 15px;">Correo</label>
                                            <InputText v-model="this.proforma.customer.email" id="customerEmail" type="email" :disabled="this.proforma.id !== null"/>
                                        </div>
                                        <div class="col-12 mb-2 lg:col-4 lg:mb-0">
                                            <label for="customerPhone" style="margin-top: 15px;">Teléfono</label>
                                            <InputText v-model="this.proforma.customer.phone" id="customerPhone" type="number" :disabled="this.proforma.id !== null"/>
                                        </div>
                                        <div class="col-12 mb-2 lg:col-4 lg:mb-0">
                                            <label for="customerAddress" style="margin-top: 15px;">Dirección</label>
                                            <InputText v-model="this.proforma.customer.address" id="customerAddress" type="text" :class="{'p-invalid': this.addressError}" @change="checkForm()" :disabled="this.proforma.id !== null"/>
                                            <small class="p-invalid" v-if="this.addressError">Ingrese una dirección</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="grid formgrid" style="margin-top: 20px;">
                            <div class="col-12">
                                <div class="card">
                                    <h5>Producto(s) / Servicio(s)</h5>
                                    <Button icon="pi pi-search" v-if="this.proforma.id === null" class="p-button-rounded p-button-info mr-2 mb-2" style="position: absolute; top: 15px; right: 20px;" @click="showProductDialog"/>
                                    <DataTable :value="this.proforma.items" editMode="cell" @cell-edit-complete="onAmountChanged" :scrollable="true" scrollHeight="1200px" scrollDirection="both" class="mt-3 editable-cells-table">
                                        <Column field="productId" header="Cód." :style="{width: '10%','min-width':'50px'}">
                                            <template #body="slotProps">
                                                {{ slotProps.data.code !== undefined && slotProps.data.code !== null ? slotProps.data.code : slotProps.data.productId  }}
                                            </template>
                                        </Column>
                                        <Column field="amount" header="Cant." :style="{width: '12.5%','min-width':'100px'}">
                                            <template #editor="{data, field}" v-if="this.proforma.id === null">
                                                <InputText v-model="data[field]" type="number" autofocus :disabled="this.proforma.id !== null"/>
                                            </template>
                                        </Column>
                                        <Column field="product" header="Descr." :style="{width: '30%','min-width':'75px'}"></Column>
                                        <Column field="readableTax" header="IVA" :style="{width: '10%','min-width':'50px'}"></Column>
                                        <Column field="unitPrice" header="Precio Unit." :style="{width: '12.5%','min-width':'50px'}">
                                            <template #editor="{data}" v-if="this.proforma.id === null">
                                                <Dropdown v-model="data.unitPrice" :options="data.priceList" :disabled="this.proforma.id !== null"/>
                                            </template>
                                        </Column>
                                        <Column field="readableDiscount" header="Descto. %" :style="{width: '12.5%','min-width':'100px'}">
                                            <template #editor="{data, field}" v-if="this.proforma.id === null">
                                                <InputText v-model="data[field]" type="number" autofocus :disabled="this.proforma.id !== null" @change="onDiscountChanged(data)"/>
                                            </template>
                                        </Column>
                                        <Column field="totalPrice" header="Precio Final" :style="{width: '10%','min-width':'50px'}">
                                            <template #body="slotProps">
                                                {{(slotProps.data.totalPrice).toFixed(2)}}
                                            </template>
                                        </Column>
                                        <Column :exportable="false" style="width: 50px;">
                                            <template #body="item" v-if="this.proforma.id === null">
                                                <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text mr-2 mb-2"  @click="onItemDeleted(item)"/>
                                            </template>
                                        </Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>

                        <div class="grid formgrid" style="margin-top: 20px;">
                            <div class="col-12">
                                <div class="card">
                                    <div class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-6">
                                            <h5 v-if="this.proforma.id === null || (this.proforma.id !== null && this.proforma.observations)">Observaciones</h5>
                                            <Textarea v-if="this.proforma.id === null || (this.proforma.id !== null && this.proforma.observations)" :disabled="this.proforma.id !== null" id="observations" rows="2" v-model="this.proforma.observations" style="margin-bottom: 10px;"/>
                                           
                                            <div class="expiryContainer">
                                                <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                                    <h5>Días de validez</h5>
                                                    <div class="expirySubcontainer">
                                                        <div style="width: 100%;">
                                                            <Dropdown v-model="validDay" :options="validDays" optionLabel="name" @change="onValidDaysChanged" placeholder="Días de duración" :disabled="this.proforma.id !== null" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                                <h5 style="margin-bottom: 5px;">Pago</h5>
                                                <Dropdown v-model="this.proforma.paymentMethod" :options="paymentMethods" optionLabel="name" placeholder="Forma de pago" :disabled="this.proforma.id !== null"/>
                                            </div>
                                            <div v-if="this.proforma.id !== null" style="margin-top: 10px;">
                                                <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                                    <h5 style="margin: 5px 0px;">Secuencial</h5>
                                                    <h6 style="margin: 0px; font-weight: 300;">{{ this.proforma.serial.toString().padStart(9, "0") }}</h6>

                                                    <h5 style="margin: 10px 0px 5px 0px;">Fecha Emisión</h5>
                                                    <label style="margin: 0px; font-weight: 300;">{{ this.proforma.createdAt.toLocaleDateString("es-ES") }}</label>

                                                    <h5 style="margin: 10px 0px 5px 0px;">Fecha Expiración</h5>
                                                    <label style="margin: 0px; font-weight: 300;">{{ (this.proforma.expiresAt !== null) ? this.proforma.expiresAt.toLocaleDateString("es-ES") : "No expira" }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="field col-12 md:col-6 saleInformationContainer">
                                            <div style="display: flex;">
                                                <label>Subtotal 0%</label>
                                                <label style="margin: 0px 0px 0px auto;">{{ this.proforma.sub0.toFixed(2) }}</label>
                                            </div>
                                            <div style="display: flex;">
                                                <label>Subtotal {{ this.proforma.taxPercentage }}%</label>
                                                <label style="margin: 0px 0px 0px auto;">{{ this.proforma.sub12.toFixed(2) }}</label>
                                            </div>
                                            <div style="display: flex;">
                                                <label>Subtotal sin impuestos</label>
                                                <label style="margin: 0px 0px 0px auto;">{{ (this.proforma.sub0 + this.proforma.sub12).toFixed(2) }}</label>
                                            </div>
                                            <div style="display: flex;">
                                                <label>IVA ({{ this.proforma.taxPercentage }}%)</label>
                                                <label style="margin: 0px 0px 0px auto;">{{ (this.proforma.tax).toFixed(2) }}</label>
                                            </div>
                                            <div style="display: flex;">
                                                <label>Descuento</label>
                                                <label style="margin: 0px 0px 0px auto;">{{ this.proforma.discount.toFixed(2) }}</label>
                                            </div>
                                            <div style="display: flex;">
                                                <label>Total</label>
                                                <label style="margin: 0px 0px 0px auto;">{{ this.proforma.total.toFixed(2) }}</label>
                                            </div>
                                        </div>
                                        <div class="field col-12 md:col-12 saleFormButtonContainer" style="margin-top: 35px;">
                                            <Button label="Limpiar" v-show="showClearButton" class="localButton p-button-raised p-button-danger mr-2 mb-2"  @click="clearProforma"/>
                                            <Button label="Anular" v-show="showDeleteButton" :loading="loadingDeleteButton" class="localButton p-button-raised p-button-danger mr-2 mb-2"  @click="displayDeleteProformaConfirmation(true)"/>
                                            <div style="margin-left: auto;"></div>
                                            <Button label="Guardar" v-show="showSaveButton" :loading="loadingSaveButton" class="localButton p-button-raised p-button-success mr-2 mb-2"  @click="saveProforma()"/>
                                            <Button label="Enviar" v-show="showSendEmailButton" icon="pi pi-envelope" class="localButton p-button-raised p-button-info mr-2 mb-2"  @click="showSendProformaEmailDialog()" />
                                            <Button type="button" label="Descargar PDF" v-show="showDownloadPDFButton" icon="pi pi-file-pdf" class="localButton p-button-raised p-button-success mr-2 mb-2" :loading="loadingDownloadProformaButton" @click="downloadProformaPDF()"/>
                                            <Button label="Crear Venta" v-show="showCreateSaleButton" class="localButton p-button-raised p-button-success mr-2 mb-2"  @click="createSale()" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Dialog header="Confirmación" v-model:visible="showDeleteProformaConfirmation" :style="{width: '350px'}" :modal="true">
                            <div class="flex align-items-center justify-content-center">
                                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                                <span>¿Está seguro de anular esta proforma?</span>
                            </div>
                            <template #footer>
                                <Button label="No" @click="displayDeleteProformaConfirmation(false)" class="p-button-secondary mr-2 mb-2"/>
                                <Button label="Sí" @click="deleteProforma" class="p-button-warning mr-2 mb-2" autofocus />
                            </template>
                        </Dialog>

                        <Dialog v-model:visible="customerDialog" :style="{'max-width': '450px', width: '90%'}" header="Buscar Cliente" :modal="true" class="p-fluid">
                            <Listbox :modelValue="selectedCustomer" :options="customerOptions" optionLabel="name" :filter="true" :filterFields="customerFilterFields" @filter="onCustomerFilterChanged" @change="onCustomerSelected"/>
                            <template #footer>
                                <Button label="Cerrar" class="p-button-secondary mr-2 mb-2" @click="hideCustomerDialog"/>
                            </template>
                        </Dialog>

                        <Dialog v-model:visible="productDialog" :style="{'max-width': '550px', width: '90%'}" header="Buscar Producto" :modal="true" class="p-fluid">
                            <AutoComplete placeholder="Buscar por nombre..." id="dd" :dropdown="false" :multiple="false" v-model="searchedProduct" @complete="onProductFilterChanged($event)" inputStyle="width: 100%;" style="width: 100%; margin-top: 5px;" :selectOnFocus="true"/>
                            <DataTable :value="this.productOptions" v-model:selection="selectedProduct" selectionMode="single" @rowSelect="onProductSelected" :scrollable="true" scrollHeight="400px" scrollDirection="both" class="mt-3 editable-cells-table">
                                <Column field="code" header="Cód." :style="{width: '25%','min-width':'70px'}"></Column>
                                <Column field="name" header="Nombre." :style="{width: '50%','min-width':'100px'}"></Column>
                                <Column field="stock" header="Stock" :style="{width: '5%','min-width':'60px'}">
                                    <template #body="slotProps">
                                        {{ (slotProps.data.stock !== undefined && slotProps.data.stock !== null) ? slotProps.data.stock : "-" }}
                                    </template>
                                </Column>
                                <Column field="price" header="Precio" :style="{width: '10%','min-width':'70px'}">
                                    <template #body="slotProps">
                                        ${{ slotProps.data.price }}
                                    </template>
                                </Column>
                            </DataTable>
                            <Divider layout="horizontal" align="center">
                                <b>O agregue un producto manualmente</b>
                            </Divider>
                            <div class="grid formgrid">
                                <div class="col-12 mb-2 lg:col-4 lg:mb-0">
                                    <InputText ref="code" type="text" v-model="this.newProduct.code" placeholder="Código (opcional)" autofocus style="margin-top: 5px;"/>
                                </div>
                                <div class="col-12 mb-2 lg:col-8 lg:mb-0">
                                    <InputText ref="productDescription" type="text" v-model="this.newProduct.name" placeholder="Nombre" autofocus style="margin-top: 5px;"/>
                                </div>
                            </div>
                            <div class="grid formgrid mt-3">
                                <div class="col-12 mb-2 lg:col-4 lg:mb-0">
                                    <InputText ref="unitPrice" type="number" v-model="this.newProduct.price" placeholder="Precio unit." autofocus style="margin-top: 5px;"/>
                                </div>
                                <div class="col-12 mb-2 lg:col-8 lg:mb-0"  style="display: flex; margin-top: 1rem;">
                                    <label style="margin-right: auto;"> Lleva IVA ({{ this.$store.getters.business.taxPercentage }}%)</label>
                                    <InputSwitch v-model="this.newProduct.hasTax" style="margin-left: auto;"/>
                                </div>
                                <Button label="Agregar" class="p-button-success mr-2 mb-2 mt-3" style="width: auto; margin: 0px 0px 0px auto;" @click="onProductSelected({data: this.newProduct})"/>
                            </div>
                        </Dialog>

                        <Dialog v-model:visible="sendProformaEmailDialog" :style="{'max-width': '550px', width: '90%'}" header="Liste el correo del o los destinatarios" :modal="true" class="p-fluid">
                            <div class="grid formgrid" style="margin-bottom: 20px;">
                                <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                    <label>Correo 1</label>
                                    <InputText type="text" v-model="this.emails[0]" />
                                    <small v-show="this.email1Error" class="p-error">Correo electrónico invalido</small>
                                </div>
                            </div>

                            <div class="grid formgrid" style="margin-bottom: 20px;">
                                <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                    <label>Correo 2 (Opcional)</label>
                                    <InputText type="text" v-model="this.emails[1]"/>
                                    <small v-show="this.email2Error" class="p-error">Correo electrónico invalido</small>
                                </div>
                            </div>

                            <div class="grid formgrid" style="margin-bottom: 20px;">
                                <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                    <label>Correo 3 (Opcional)</label>
                                    <InputText type="text" v-model="this.emails[2]"/>
                                    <small v-show="this.email3Error" class="p-error">Correo electrónico invalido</small>
                                </div>
                            </div>

                            <template #footer>
                                <Button label="Cerrar" class="p-button-secondary mr-2 mb-2" @click="hideSendEmailDialog"/>
                                <Button label="Enviar" class="p-button-success mr-2 mb-2" @click="sendEmail"/>
                            </template>
                        </Dialog>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>

import download from 'downloadjs'
import httpRequest from '../../service/httpRequest';
import Proforma from '../../models/proforma';
import ProformaItem from '../../models/proformaItem';
import Customer from '../../models/customer';
import Product from '../../models/product';

export default {
	data() {
		return {
            proforma: new Proforma(),
            cardBackgroundColor: "white",

            customerOptions: [],
            customerFilterFields: ['name', 'identification', 'email'],
            selectedCustomer: null,
            customerDialog: false,

            productOptions: [],
            selectedProduct: null,
            searchedProduct: null,
            newProduct: new Product(),
            productDialog: false,

            error: {},

            nameError: false,
			identificationError: false,
			addressError: false,

            sendProformaEmailDialog: false,
            emails: ["","",""],
            email1Error: false,
            email2Error: false,
            email3Error: false,

            scannerBuffer: new String(),

            // Show
            showNewButton: false,
            showClearButton: false,
            showSaveButton: false,
            showSendEmailButton: false,
            showCreateSaleButton: false,
            showDeleteButton: false,
            showDeleteProformaConfirmation: false,
            showDownloadPDFButton: false,

            // Loading
            loadingSaveButton: false,
            loadingDeleteButton: false,
            loadingDownloadProformaButton: false,
            
            paymentMethods: [
                {name: 'Efectivo', code: 'CASH'},
                {name: 'Transferencia', code: 'TRANSFER'},
                {name: 'Tarjeta Débito', code: 'DEBIT_CARD'},
                {name: 'Tarjeta Crédito', code: 'CREDIT_CARD'},
                {name: 'Cheque', code: 'CHECK'},
            ],
            validDays: [
                {name: 'No expira', code: '-1'},
                {name: '1 días', code: '1'},
                {name: '3 días', code: '3'},
                {name: '7 días', code: '7'},
                {name: '14 días', code: '14'},
                {name: '21 días', code: '21'},
                {name: '30 días', code: '30'},
                {name: '45 días', code: '45'},
                {name: '60 días', code: '60'},
                {name: '90 días', code: '90'},
            ],
            validDay: {name: '14 días', code: '14'},
		}
	},
    async created() {
        window.scrollTo(0, 0);

        // Get sale
        let proformaId = this.$route.query.id;
        if (proformaId !== null && proformaId !== undefined) {
            let response = await httpRequest.getProforma(proformaId);
            if (response.status == 200) {
                this.proforma = new Proforma(response.data);
                this.setupUI();
                // Setup duedate/validDays dropdown
                this.validDay = this.getValidDaysDic(this.proforma.validDays);
            }
        } else { this.setupUI(); }
        
        // Register event listener for scanner
        document.addEventListener('keydown', this.onScanned);
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.onScanned);
    },
	methods: {
        async onScanned(event) {
            var charCode = (typeof event.which === "number") ? event.which : event.key
            if (charCode !== 13) {
                this.scannerBuffer += String.fromCharCode(charCode);
            } else {
                let filter = this.scannerBuffer.replace('undefined', '');
                this.scannerBuffer = "";
                if (filter > 4) {
                    const result = await httpRequest.getProducts(filter);
                    if (result.status === 200 && result.data.length === 1) {
                        const product = new Product(result.data[0]);
                        this.onProductSelected({data: product});
                    }
                    return;
                }
            }
        },
        async setupUI() {
            this.cardBackgroundColor = "white";
        
            this.showNewButton = this.proforma.id !== null;
            this.showClearButton = this.proforma.id === null;
            this.showDeleteButton = this.proforma.id !== null && this.proforma.saleId === null;
            this.showSaveButton = this.proforma.id === null;
            this.showSendEmailButton = this.proforma.id !== null; 
            this.showCreateSaleButton = this.proforma.id !== null && this.proforma.saleId === null;
            this.showDownloadPDFButton = this.proforma.id !== null;
        },
        newProforma() {
            let path = "/dashboard/proforma/form"
            history.pushState(
                {},
                null,
                path
            )
            this.$router.go(path);
        },  

        // Customer
        checkForm() {
			var error = false;
			if (this.proforma.customer.name === null || this.proforma.customer.name === undefined || this.proforma.customer.name === "") {
				this.nameError = true;
				error = true;
			} else { this.nameError = false }
			if (this.proforma.customer.identification === null || this.proforma.customer.identification === undefined ) {
				this.identificationError = true;
				error = true;
			} else if (/^\d+$/.test(this.proforma.customer.identification) && this.proforma.customer.identification.length !== 10 && this.proforma.customer.identification.length !== 13) {
				this.identificationError = true;
				error = true;
			} else { this.identificationError = false }
			if (this.proforma.customer.address === null || this.proforma.customer.address === undefined || this.proforma.customer.address === "") {
				this.addressError = true;
				error = true;
			} else { this.addressError = false }
			return error;
		},
        showCustomerDialog() {
            this.customerDialog = true;
        },
        hideCustomerDialog() {
            this.customerDialog = false;
        },
        async onCustomerFilterChanged(event) {
            let filter = event.value;
            if (filter.length > 1) {
                const result = await httpRequest.getCustomers(filter);
                if (result.status === 200) {
                    this.customerOptions = result.data.map(customer => new Customer(customer));
                } else { this.customerOptions = [] }
            }
        },
        onCustomerSelected(event) {
            let customer = new Customer(event.value)
            this.proforma.customer = customer;
            this.selectedCustomer = null;
            this.customerDialog = false;
        },

        // Product
        showProductDialog() {
            this.productDialog = true;
        },
        showProductMenuDialog() {
            this.productMenuDialog = true;
        },  
        hideProductDialog() {
            this.productDialog = false;
        },
        hideProductMenuDialog() {
            this.productMenuDialog = false;
        },
        async onProductFilterChanged(event) {
            let filter = event.query;
            if (filter.length > 1) {
                const result = await httpRequest.getProducts(filter);
                if (result.status === 200) {
                    this.productOptions = result.data.map(product => new Product(product));
                } else { this.productOptions = [] }
            }
        },
        onProductSelected(data) {
            let product = new Product(data.data);
            let object = {
                productId: product.id,
                code: product.code,
                product: product.name,
                productObj: new Product(product),
                hasTax: product.hasTax,
                discount: product.discount,
                unitPrice: product.price,
                totalPrice: product.price,
                priceList: product.priceList,
                amount: product.selectedAmount
            }
            
            let item = new ProformaItem(object);
            this.proforma.updateItems(item);
            
            this.selectedProduct = null;
            this.productDialog = false;
            this.newProduct = new Product();

            // Animate selection
            data.data.selectedAmount = 1;
            data.data.productSelected = true;
            setTimeout(() => {
                data.data.productSelected = false
            }, 500)
        },
        onAmountChanged(event) {
            let item = new ProformaItem(event.newData);
            this.proforma.updateItems(item, false);
        },
        onSelectedAmountChanged(data, amount) {
            if (amount < 0 && data.selectedAmount == 1) { return }
            data.selectedAmount += amount;
        },
        onSelectedPriceChanged(product, price) {
            product.price = price;
        },
        onPriceSelected(event) {
            this.$refs.priceMenu.toggle(event);
        },
        onItemDeleted(item) {
            this.proforma.deleteItem(item.data);
        },
        onDiscountChanged(data) {
            data.discount = data.readableDiscount / 100;
            let item = new ProformaItem(data);
            this.proforma.updateItems(item, false);
        },

        // Proforma
        clearProforma() {
            this.proforma = new Proforma();
        },
        async saveProforma() {
            let result = this.checkForm();
            if (!result) {
                if (this.proforma.items !== undefined && this.proforma.items.length > 0) {
                    // Enable loading mode
                    this.loadingSaveButton = true;

                    this.proforma.paymentMethod = this.proforma.paymentMethod.code;
                    let response = await httpRequest.saveProforma(this.proforma);

                    // Disable loading mode
                    this.loadingSaveButton = false;

                    if (response.data !== undefined && response.data !== null) {
                        this.proforma = new Proforma(response.data);
                        this.setupUI();
                        history.pushState(
                            {},
                            null,
                            this.$route.path + '?id=' + this.proforma.id
                        )
                    }
                    if (response.status === 200) {
                        if (this.proforma.customer.email !== null) { // Response if proforma is sent to an email
                            const autoEmailSend = await this.$store.getters.business.defaults.settings.proforma_sendAutoEmail;
                            const title = autoEmailSend ? "Proforma enviada" : "Proforma generada";
                            const message = ("Su proforma ha sido generada con éxito") + (autoEmailSend ? (" y enviada exitosamente al correo: " + this.proforma.customer.email) : ".");
                            this.$toast.add({severity:'success', summary: title, detail: message, life: 3000});
                        } else { // Default success answer
                            this.$toast.add({severity:'success', summary: 'Proforma generada', detail: 'Su proforma ha sido generada con éxito.', life: 3000});
                        }
                    } else {
                        this.$toast.add({severity:'error', summary: 'Error interno', detail: 'Hemos detectado un error, por favor intente nuevamente.', life: 3000});
                    }
                } else {
                    this.$toast.add({severity:'error', summary: 'Error guardando proforma', detail: 'Agregue uno o más productos/servicios', life: 3000});
                }
            }
        },
        createSale() {
            let path = "/dashboard/venta/form?proformaId=" + this.proforma.id
            history.pushState(
                {},
                null,
                path
            )
            this.$router.go(path);
        },
        async deleteProforma() {
            this.loadingDeleteButton = true;
            let response = await httpRequest.deleteProforma(this.proforma.id);
            this.loadingDeleteButton = false;
            if (response.status === 200) {
                this.$router.push('/dashboard/proforma/crud');
            } else {
               this.$toast.add({severity:'error', summary: 'Error eliminando proforma', detail: 'Por favor intente nuevamente, si el error continua comuníquese con el proveedor.', life: 3000}); 
            }
            this.showDeleteProformaConfirmation = false;
        },
        onValidDaysChanged() {
            this.proforma.validDays = parseInt(this.validDay.code);
        },
        getValidDaysDic(validDays) {
            for (var i in this.validDays) {
                if (this.validDays[i].code == validDays) { return this.validDays[i]; }  
            }
            return this.validDays[0];
        },
        displayDeleteProformaConfirmation(show) {
            this.showDeleteProformaConfirmation = show;
        },
        showSendProformaEmailDialog() {
            this.sendProformaEmailDialog = true;
        },
        hideSendEmailDialog() {
            this.sendProformaEmailDialog = false;
        },
        validateEmail(email) {
            return String(email)
                .toLowerCase()
                .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ) !== null;
        },
        async sendEmail() {
            if (this.emails[0] === "" && this.emails[1] === "" && this.emails[2] === "") { 
                this.sendProformaEmailDialog = false;
                return;
            }

            this.email1Error = (this.emails[0] !== "") && (this.validateEmail(this.emails[0]) === false);
            this.email2Error = (this.emails[1] !== "") && (this.validateEmail(this.emails[1]) === false);
            this.email3Error = (this.emails[2] !== "") && (this.validateEmail(this.emails[2]) === false);

            if (this.email1Error === false && this.email2Error === false && this.email3Error === false) {
                let response = await httpRequest.sendProformaEmail(this.proforma.id, this.emails)
                if (response.status === 200) {
                    this.$toast.add({severity:'success', summary: 'Proforma enviada', detail: 'La proforma ha sido enviada a la lista de destinatarios', life: 3000}); 
                } else {
                    this.$toast.add({severity:'error', summary: 'Error eliminando proforma', detail: 'Por favor intente nuevamente, si el error continua comuníquese con el proveedor.', life: 3000}); 
                }
                this.sendProformaEmailDialog = false;
            }
        },

        // PDF
        toggleDownloadMenu(event) {
            this.$refs.menu.toggle(event);
        },
        async downloadProformaPDF() {
            this.loadingDownloadProformaButton = true;
            const response = await httpRequest.downloadProformaFile(this.proforma.id);
            download(response, "proforma_" + this.proforma.serial + ".pdf", "application/pdf");
            this.loadingDownloadProformaButton = false;
        },
	}
}
</script>

<style scoped lang="scss">

@import '../../assets/demo/badges.scss';

    .menuButton {
        width: 100%;
        background-color: white;
        border: 0px;
        color: darkgrey;
    }
    .menuButton:hover {
        background-color: gray;
    }
    .newsale-button {
        position: absolute;
        top: 13px;
        right: 10px;
    }
    .p-menu {
        width: 40px;
    }
    .dueDateLabel {
        font-size: small;
        font-weight: 200;
    }
    .productSelected {
        background-color: green !important;
    }
    @media screen and (max-width: 769px) {
        .saleFormButtonContainer {
            display: block;
        }
        .expiryContainer {
            display: initial;
        }
        .expiryContainer > div > h5 {
            margin: 10px 0 5px 0; 
        }
        .expirySubcontainer {
            display: block;
        }
        .collectButton {
            margin-top: 10px;
            padding: 0px;
            width: 100%;
        }
        .localButton {
            width: 100%
        }
        .saleform-title {
            font-size: small;
        }
    }
    @media screen and (min-width: 769px) {
        .saleFormButtonContainer {
            display: flex;
        }
        .saleInformationContainer {
            padding-left: 50px;
        }
        .expiryContainer {
            display: flex;
            margin-bottom: 5px;
        }
        .expiryContainer > div > h5 {
            margin-bottom: 5px; 
        }
        .expirySubcontainer {
            display: flex;
        }
        .collectButton {
            margin-left: 10px;
            max-width: 150px;
        }
        .localButton {
            width: auto;
        }
        .saleform-title {
            font-size: large;
        }
    }

</style>