import Customer from "./customer";
import ProformaItem from "./proformaItem";
import EconActivity from "./econActivity";
import store from "../store";

const Proforma = class {
    constructor(object) {
        if (object === undefined) {
            this.id = null;
            this.saleId = null;
            this.serial = null;
            this.econActivity = null;
            this.customer = new Customer();
            this.items = [];
            this.createdAt = null;
            this.expiresAt = null;
            this.validDays = 15;
            this.total = 0.0;
            this.discount = 0.0;
            this.sub0 = 0.0;
            this.sub12 = 0.0;
            this.tax = 0.0;
            this.taxPercentage = store.getters.business.taxPercentage;
            this.paymentMethod = {name: 'Efectivo', code: 'CASH'};
            this.observations = null;
            this.status = ""
        } else {
            this.id = object.id;
            this.saleId = object.saleId || null;
            this.serial = object.serial;
            this.econActivity = new EconActivity(object.activity);
            this.customer = new Customer(object.customer);
            this.createdAt = new Date(Date.parse(object.createdAt));
            this.validDays = object.validDays;
            this.total = parseFloat(object.total);
            this.discount = parseFloat(object.discount) || 0.0;
            this.sub0 = parseFloat(object.base0);
            this.sub12 = parseFloat(object.base12);
            this.tax = parseFloat(object.tax);
            this.taxPercentage = object.taxPercentage || store.getters.business.taxPercentage;
            this.paymentMethod = object.paymentMethod;
            this.observations = object.observations;
            this.status = object.status;

            // Parse expires at
            if (object.expiresAt !== undefined) {
                this.expiresAt = new Date(Date.parse(object.expiresAt));
            } else { this.expiresAt = null }

            // Parse items if exists
            if (object.items !== undefined && object.items !== null && object.items.length > 0) { 
                this.items = object.items.map(item => new ProformaItem(item)); 
            }
        }
    }
    get createdAtFormatted() {
        var options = { year: 'numeric', month: 'numeric', day: 'numeric'};
        return this.createdAt.toLocaleDateString('es-EC', options) + " - " + this.createdAt.toLocaleTimeString('en-US');
    }
    get expiresAtDateFormatted() {
        if (this.expiresAt !== null) {
            var options = { year: 'numeric', month: 'numeric', day: 'numeric'};
            return this.expiresAt.toLocaleDateString('es-EC', options);
        }
        return null;
    }
    get state() {
        if (this.status == "GENERATED") { return "GENERADA" }
        else if (this.status == "EXPIRED") { return "EXPIRADA" }
        else { return "FACTURADA" }
    }
    get stateBadgeColor() {
        if (this.status == "GENERATED") { return "blue" }
        else if (this.status == "EXPIRED") { return "red" }
        else { return "green" }
    }
    get paymentMethodReadable() {
        if (this.paymentMethod === "CASH") { return "Efectivo"}
        if (this.paymentMethod === "TRANSFER") { return "Transferencia"}
        if (this.paymentMethod === "DEBIT_CARD") { return "Tarj. Debito"}
        if (this.paymentMethod === "CREDIT_CARD") { return "Tarj. Credito"}
        else { return "Cheque" }
    }
    get paymentMethodBadgeColor() {
        if (this.paymentMethod === "CASH") { return "green"}
        if (this.paymentMethod === "TRANSFER") { return "blue"}
        else { return "gray" }
    }
    updateItems(item, append=true) {
        if (append) { this.items.unshift(item) }
        else {
            let index = this.getItemIndex(item);
            if (index === -1) { this.items.unshift(item) } 
            else { this.items[index] = item }
        }
        this.updateValues();
     }
    deleteItem(item) {
        let index = this.getItemIndex(item);
        this.items.splice(index, 1);
        if (this.items.length == 0) { this.items = [] }
        this.updateValues();
    }
    getItemIndex(item) {
        var index = -1;
        if (item.uid === null || this.items === null || this.items === undefined) { return -1; }
        for (var i=0; i<this.items.length; i++) {
            if (this.items[i].uid === item.uid) { return i; }
        }
        return index;
    }
    updateValues() {
        var sub0_ = 0.0;
        var sub12_ = 0.0;
        var discount_ = 0.0;
        for (var i=0; i<this.items.length; i++) {
            if (this.items[i].hasTax) {
                sub12_ += this.items[i].totalPrice;
            } else {
                sub0_ += this.items[i].totalPrice;
            }
            discount_ += (this.items[i].amount * this.items[i].unitPrice * this.items[i].discount)
        }
        this.sub0 = sub0_;
        this.sub12 = sub12_;
        this.tax = sub12_ * store.getters.business.tax;
        this.discount = discount_;
        this.total = sub0_ + sub12_ + this.tax;
    }
}

export default Proforma